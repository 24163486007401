@font-face {
  font-family: roboto;
  src: url("../font/Roboto-Regular.ttf");
}
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Arial, Tahoma, sans-serif
}
.ul-list li {
  padding-bottom: 10px !important;
}
.dropdown {
  float: left;
  overflow: hidden;
}
.dropdown .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
/* Style the subnav content - positioned absolute */
.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: red;
  width: 100%;
  z-index: 1;
}

.dropdown-content {
  position: absolute;
  background-color: rgba(15, 131, 131, 0.8);
  z-index: 1;
  margin-left: 430px;
  margin-top: 16px;
}
.dropdown-content a {
 display: none;
 padding: 20px;
}
.courses-menu:hover a {
  display: block;
 }
.main-home h1 {
  padding-top: 140px;
  font-size: 70px;
  color: whitesmoke;
}
.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
.home {
  background: url("../images/home.jpg");
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: rgba(255, 255, 255, 0.582);
  background-attachment: fixed;
}
.feedback {
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  background-color: rgba(255, 255, 255, 0.582);
  background-size: cover;
  background-attachment: fixed;
}
.services {
  padding-bottom: 50px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.employAdvice {
  background: url("../images/services.jpg");
  padding-bottom: 50px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.faAdvice {
  padding-bottom: 50px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.TaxAdvice {
  padding-bottom: 50px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.advice {
  padding-bottom: 50px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.navbar ul {
  list-style: none;
  padding-top: 38px;
  padding-bottom: 17px;
  background: rgba(15, 131, 131, 0.5);
  color: white;
  position: fixed;
  z-index: 1 !important;
  right: 0%;
  left: 0%;
  top: 0%;
}
.navbar ul li {
  padding: 30px;
  display: inline;
}
.navbar ul span {
  cursor: pointer;
}
.navbar ul a:hover {
  cursor: pointer;
  background-color: rgb(15, 131, 131);
  padding-bottom: 18px;
  padding-top: 20px;
}
.image-link:hover {
  background-color: transparent !important;
}
.Quote {
  
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.footer ul li {
  padding-bottom: 10px;
  color: gray;
}
.footer {
  background: #eee;
}
.section {
  background: aliceblue;
}
.about {
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 20px;
  right: 36px;
  top: 36px;
  display: none;
  z-index: 2 !important;
  
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(15, 131, 131);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(15, 131, 131, 0.7);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgba(15, 131, 131, 0.8);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(15, 131, 131, 0.5);
}
.add {
  display: none;
}
.form {
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: soft-light;
  background-color: rgba(0, 0, 0, 0.7);
}
.login,
.signup {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-blend-mode: lighten;
  background-color: gray;
  text-align: center;
  color: rgb(15, 131, 131);
  
}
.sidebar {
  width: 55px;
  background-color: rgb(55, 97, 121);
  height: 100vh;
  position: fixed;
  color: white;
  z-index: 1;
  top: 0;
}
.sidebar ul {
  list-style: none;
}
.sidebar li {
  padding: 15px;
  cursor: pointer;
}
.sidebar li:hover {
  background-color: teal;
}
.sidebar:hover {
  width: 150px;
  transition: width 0.2s ease-in;
}

.sidebar:hover span {
  display: inline;
}
.sidebar li span {
  position: absolute;
  padding-left: 15px;
  display: none;
}

.navbar1 {
  height: 50px;
  margin-left: 55px;
  position: fixed !important;
  z-index: 1;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  color: rgb(15, 131, 131);
}

.quiz {
  color: rgb(15, 131, 131);
}
.quiz:hover {
  background: linear-gradient(
    100deg,
    transparent,
    rgb(200, 235, 255),
    transparent
  );
}
.profile {
  margin-left: 70px;
  margin-top: 20px;
  text-align: center;
  color: rgb(15, 131, 131);
  margin-right: 15px;
}
.edit:hover,
.delete:hover {
  font-size: 25px !important;
}
.color {
  color: rgb(15, 131, 131);
}

.fb:hover {
  color: #3b5998 !important;
}

.whatsapp:hover {
  color: #25D366 !important;
}

table th {
  font-size: 0.9rem;

  color: "gray";
}
table td {
  font-size: 0.9rem;
}
table.table thead th {
  border-top: none;
  font-weight: bold;
}
table.table th,
table.table td {
  padding-top: 1.1rem;
  padding-bottom: 1rem;
}
table.table a {
  margin: 0;
  color: #010102;
}
table.table .label-table {
  height: 0.94rem;
  padding: 0;
  margin: 0;
  line-height: 0.94rem;
}
table.table.btn-table td {
  vertical-align: middle;
}
table.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
  transition: 0.5s;
}
table .th-lg {
  min-width: 9rem;
}
table .th-sm {
  min-width: 6rem;
}
table.table-sm th,
table.table-sm td {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.table-scroll-vertical {
  max-height: 300px;
  overflow-y: auto;
}
.table-fixed {
  table-layout: fixed;
}
.table-responsive > .table-bordered,
.table-responsive-sm > .table-bordered,
.table-responsive-md > .table-bordered,
.table-responsive-lg > .table-bordered,
.table-responsive-xl > .table-bordered {
  border-top: 1px solid #dee2e6;
}
div.dataTables_wrapper div.dataTables_length select,
div.dataTables_wrapper div.dataTables_length input {
  width: auto;
}
div.dataTables_wrapper div.dataTables_length.d-flex.flex-row label {
  margin-top: 1.2rem;
  margin-right: 1rem;
}
div.dataTables_wrapper
  div.dataTables_length.d-flex.flex-row
  .select-wrapper.mdb-select
  span,
div.dataTables_wrapper
  div.dataTables_length.d-flex.flex-row
  .select-wrapper.mdb-select
  .select-dropdown {
  margin-top: 1rem;
}
div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_filter input {
  width: auto;
}
div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
  margin-left: 0.5rem;
}
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 400;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-left: 40%;
}
div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  .page-item.active
  .page-link:focus {
  background-color: #4285f4;
}
div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  .page-item
  .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #b0bed9;
}
table.dataTable.stripe tbody > tr.odd.selected,
table.dataTable.stripe tbody > tr.odd > .selected,
table.dataTable.display tbody > tr.odd.selected,
table.dataTable.display tbody > tr.odd > .selected {
  background-color: #acbad4;
}
table.dataTable.hover tbody > tr.selected:hover,
table.dataTable.hover tbody > tr > .selected:hover,
table.dataTable.display tbody > tr.selected:hover,
table.dataTable.display tbody > tr > .selected:hover {
  background-color: #aab7d1;
}
table.dataTable.order-column tbody > tr.selected > .sorting_1,
table.dataTable.order-column tbody > tr.selected > .sorting_2,
table.dataTable.order-column tbody > tr.selected > .sorting_3,
table.dataTable.display tbody > tr.selected > .sorting_1,
table.dataTable.display tbody > tr.selected > .sorting_2,
table.dataTable.display tbody > tr.selected > .sorting_3 {
  background-color: #acbad5;
}
table.dataTable.order-column tbody > tr > .selected,
table.dataTable.display tbody > tr > .selected {
  background-color: #acbad5;
}
table.dataTable.display tbody > tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd;
}
table.dataTable.display tbody > tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf;
}
table.dataTable.display tbody > tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1;
}
table.dataTable.display tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #acbad5;
}
table.dataTable.display tbody > tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_2 {
  background-color: #aebcd6;
}
table.dataTable.display tbody > tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}
table.dataTable.display tbody > tr.odd > .selected,
table.dataTable.order-column.stripe tbody > tr.odd > .selected {
  background-color: #a6b4cd;
}
table.dataTable.display tbody > tr.even > .selected,
table.dataTable.order-column.stripe tbody > tr.even > .selected {
  background-color: #acbad5;
}
table.dataTable.display tbody > tr.selected:hover > .sorting_1,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_1 {
  background-color: #a2aec7;
}
table.dataTable.display tbody > tr.selected:hover > .sorting_2,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_2 {
  background-color: #a3b0c9;
}
table.dataTable.display tbody > tr.selected:hover > .sorting_3,
table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_3 {
  background-color: #a5b2cb;
}
table.dataTable.display tbody > tr:hover > .selected,
table.dataTable.display tbody > tr > .selected:hover,
table.dataTable.order-column.hover tbody > tr:hover > .selected,
table.dataTable.order-column.hover tbody > tr > .selected:hover {
  background-color: #a2aec7;
}
table.dataTable tbody td.select-checkbox,
table.dataTable tbody th.select-checkbox {
  position: relative;
}
table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody td.select-checkbox:after,
table.dataTable tbody th.select-checkbox:before,
table.dataTable tbody th.select-checkbox:after {
  position: absolute;
  top: 1.2em;
  left: 50%;
  box-sizing: border-box;
  display: block;
  width: 12px;
  height: 12px;
}
table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody th.select-checkbox:before {
  margin-top: 4px;
  margin-left: -6px;
  content: " ";
  border: 1px solid #000;
  border-radius: 3px;
}
table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
  margin-top: 0;
  margin-left: -4px;
  text-align: center;
  text-shadow: 1px 1px #b0bed9, -1px -1px #b0bed9, 1px -1px #b0bed9,
    -1px 1px #b0bed9;
  content: "\2714";
}
div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  margin-left: 0.5em;
}
.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #4285f4;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
}
.pagination .page-item.active .page-link:hover {
  background-color: #4285f4;
}
.pagination .page-item.disabled .page-link {
  color: #868e96;
}
.pagination .page-item .page-link {
  font-size: 0.9rem;
  color: #212529;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: all 0.3s linear;
}
.pagination .page-item .page-link:hover {
  background-color: #eee;
  border-radius: 0.125rem;
  transition: all 0.3s linear;
  cursor: pointer;
}
.pagination .page-item .page-link:focus {
  background-color: transparent;
  box-shadow: none;
}
.pagination.pagination-lg .page-item .page-link {
  font-size: 1rem;
}
.pagination.pagination-sm .page-item .page-link {
  font-size: 0.8rem;
}
.pagination.pagination-circle .page-item .page-link {
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 50%;
}
.pagination.pagination-circle .page-item .page-link:hover {
  border-radius: 50%;
}
.pagination.pagination-circle .page-item.active .page-link {
  border-radius: 50%;
}
.pagination.pg-blue .page-item.active .page-link {
  background-color: #4285f4;
}
.pagination.pg-blue .page-item.active .page-link:hover {
  background-color: #4285f4;
}
.pagination.pg-red .page-item.active .page-link {
  background-color: #ff3547;
}
.pagination.pg-red .page-item.active .page-link:hover {
  background-color: #ff3547;
}
.pagination.pg-teal .page-item.active .page-link {
  background-color: #2bbbad;
}
.pagination.pg-teal .page-item.active .page-link:hover {
  background-color: #2bbbad;
}
.pagination.pg-dark-grey .page-item.active .page-link {
  background-color: #37474f;
}
.pagination.pg-dark-grey .page-item.active .page-link:hover {
  background-color: #37474f;
}
.pagination.pg-dark .page-item.active .page-link {
  background-color: #2e2e2e;
}
.pagination.pg-dark .page-item.active .page-link:hover {
  background-color: #2e2e2e;
}
.pagination.pg-blue-grey .page-item.active .page-link {
  background-color: #3f729b;
}
.pagination.pg-blue-grey .page-item.active .page-link:hover {
  background-color: #3f729b;
}
.pagination.pg-amber .page-item.active .page-link {
  background-color: #ff6f00;
}
.pagination.pg-amber .page-item.active .page-link:hover {
  background-color: #ff6f00;
}
.pagination.pg-purple .page-item.active .page-link {
  background-color: #5e35b1;
}
.pagination.pg-purple .page-item.active .page-link:hover {
  background-color: #5e35b1;
}

@media screen and (max-width: 1125px) {
  .foo-btn {
    position: relative !important;
    margin-left: auto !important;
    margin-right: auto !important;
    right: 0px !important;
  }
  .main-home h1 {
    font-size: 45px !important;
  }
  .main-home h5 {
    font-weight: normal !important;
    font-size: 25px !important;
  }
  .epa {
    width: 90% !important;
  }
  .about h1 {
    font-size: 50px !important;
  }
  .about h3 {
    font-size: 25px !important;
  }
  .navbar ul span {
    display: none;
  }
  .links {
    display: none !important;
    background-color: transparent !important;
  }
  .add {
    display: block;
  }
  .subtract {
    display: none;
  }
  .bm-burger-button {
    display: block;
  }
  .image {
    display: block !important;
  }
}

@media screen and (max-width: 700px) {
  .width-input {
    width: 100% !important;
  }
}

@media screen and (max-width: 870px) {
  .row2 {
    display: none !important;
  }
  .row1 {
    display: block !important;
  }
}

@media screen and (max-width: 650px) {
  .start-bar {
    display: none !important;
  }
  .quote1 {
    display: block !important;
  }
  .width-input {
    width: 100% !important;
  }
  .quote {
    display: none !important;
  }
  .serviceMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}